<template>
 <v-theme-provider dark>
  <base-section
    id="bottlewaterplant"
    class="accent"
    space="36"
  >
    <v-spacer />

    <div v-if="$route.name=='생수공장용'">
      <v-tabs
        class="hidden-sm-and-down"
        optional
        background-color="transparent"
      >
        <v-tab
          v-for="(name, i) in items"
          :key="i"
          :to="{ name }"
          :exact="name === 'Home'"
          :ripple="false"
          class="font-weight-bold"
          min-width="96"
          text
        >
          {{ name }}
        </v-tab>
      </v-tabs>
    </div>

    <v-container>
      <base-section-heading title="생수공장용 세척제?" />

      <v-container
      class="text-center"
      >
          생수공장용 세척제는 중성, 저기포, 무독성의 친환경제품으로 생수용 Polycabonate, PET, 기타 플라스틱 용기 용 세척제 입니다.<br>
          생수공장용 세적제의 경우 환경적요인이 우선 고려되어야 하며, 그 오염원에 적합한 세제가 사용되야 합니다.<br>
          <br>
          남강의 생구공장용 세척제는 다음과 같은 특성이 있습니다.<br>
          생분해도가 높고 소포도가 우수하여 페수처리가 용이 합니다.<br>
          세척력이 우수하여 유기물과 무기물 때를 동시에 제거 합니다.<br>
          저기포성으로 작업성이 뛰어 나며, 물기 제거력이 뛰어 납니다.<br>
          수용액의 분산력이 뛰어나며, 오물의 재부착을 방지 합니다.<br>
          경수에서도 강력한 세척력을 발휘 합니다.<br>
          장기간 사용으로 생길 수 있는 스케일 형성을 방지해 줍니다.<br>
          Polycabonate, PET, 기타 플라스틱 재질에 안전합니다.<br>
          불연성이며, 독성이 없어 작업이 안전합니다.<br>
          금속 부식이 없습니다.<br>
          당사가 제공하는 자동공급장치를 사용하여 정해진 농도에서 자동 투입이 가능 합니다.<br>
      </v-container>
      <v-container>
      </v-container>

      <v-container>
      </v-container>
      <base-section-heading title="제품군" />
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                생수용기(폴리카보네이트) 세척제(중성)
              </v-subheader>
            </v-card>
          </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                생수용기(폴리카보네이트) 세척제(약알카리성)
              </v-subheader>
            </v-card>
        </v-col>
    </v-container>
  </base-section>
 </v-theme-provider>
</template>

<script>
  export default {
    name: 'Sectionbottlewaterplant',
    data: () => ({
      drawer: null,
      items: [
        'Cip',
        'Cop',
        '세병첨가제',
        '컨베이어 윤활제',
        '생수공장용',
        '수질용',
      ],
      inset: false,
    }),
    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
<style scoped>
.v-subheader {
    font-size: 18px;
    color: coral !important;
}

</style>
